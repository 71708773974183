var getFavoritesList = function() {
  let favorites = localStorage.getItem('favorites');

  if(favorites === null) {
    favorites = '[]';
  }

  favorites = JSON.parse(favorites);

  return favorites;
};

var setFavoritesList = function(favorites) {
  localStorage.setItem('favorites', JSON.stringify(favorites));
}

var favoritesListingLink = function() {
  let favoritesString = localStorage.getItem('favorites');

	//Call btoa() on the string to get base64 encoding.
	favoritesString = btoa(favoritesString);
	//Get the link to the favorites page using js-favorites-link
	if (favoritesString !== null) {
	let favoritesLink = document.querySelector('.js-favorites-link');
	favoritesLink.href = `/meetings-and-conventions/favorites-listing?favoriteslist=${favoritesString}`;
	}
}

var setFavBtnState = function() {
  var favoritesButtons = document.querySelectorAll('.js-add-to-favorites');

  favoritesButtons.forEach(function(favoriteButton) {
    var btnID = parseInt(favoriteButton.getAttribute('data-id'));
    var favArr = getFavoritesList();

    if(favArr.indexOf(btnID) == -1) {
      favoriteButton.classList.remove("--filled");
      favoriteButton.innerHTML = 'Add to favorites';
    } else {
      favoriteButton.classList.add("--filled");
      favoriteButton.innerHTML = 'Remove';
    }

  });
}

var updateHeartCounter = function() {
  var favorites = getFavoritesList();
  var heartCounter = document.querySelector('.js-favorites-count');
  var favoriteCounter = favorites.length;


	heartCounter.innerHTML = favoriteCounter;

}

var runRemoveButtons = function() {
  //grab all remove buttons
  var removeButtons = document.querySelectorAll('.js-remove-from-favorites-listing');

  if(removeButtons !== null)
    removeButtons.forEach(function(removeButton) {
      removeButton.addEventListener('click', function(event) {
        event.preventDefault();

        var btnID2 = removeButton.getAttribute('data-id');
        var btnID = parseInt(removeButton.getAttribute('data-id'));
        var favorites = getFavoritesList();
        //target parent container for removal
        var listingContainer = document.querySelector(`.js-favorite-listing-row[data-id="${btnID2}"]`);

        listingContainer.classList.add('--remove');
        favorites.splice(favorites.indexOf(btnID), 1);

        setFavoritesList(favorites);
        updateHeartCounter();
        favoritesListingLink();

      });

  });

}

var runFavorites = function() {
  var favoritesButtons = document.querySelectorAll('.js-add-to-favorites');

  favoritesButtons.forEach(function(favoritesButton,i,arr) {

  	// function that is a click event to set currect id into local storage array
  	favoritesButton.addEventListener('click', function (event) {
  		// Don't follow the link
  		event.preventDefault();
      let btn = event.target;
      let favorites = getFavoritesList();

      let favID = btn.getAttribute('data-id');
      favID = parseInt(favID);

      if(favorites.indexOf(favID) == -1) {
    		favorites.push(favID);
        btn.classList.add("--filled");
        btn.innerHTML = 'Remove';
    	} else {
    		favorites.splice(favorites.indexOf(favID), 1);
        btn.classList.remove("--filled");
        btn.innerHTML = 'Add to favorites';
    	}

      setFavoritesList(favorites);
      favoritesListingLink();
      updateHeartCounter();

  	}, false);

  });
};


document.addEventListener("DOMContentLoaded",function() {
  runFavorites();
  setFavBtnState();
  favoritesListingLink();
  updateHeartCounter();
  runRemoveButtons();

});
