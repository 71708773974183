this.detailSlider = function(container, callback) {
  var $container = container;

  var init = function() {
    $slider = $container.find('.slider');
    $thumbs = $container.find('.thumbs');
    var slideCount = $slider.find('li').length;

    if(slideCount > 1) {
      $thumbs.flexslider({
        animation: 'slide',
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        itemWidth: 98,
        itemMargin: 2,
        useCSS: false,
        prevText: '<span class=\"icn-angle-left\"></span>',
        nextText: '<span class=\"icn-angle-right\"></span>',
        asNavFor: $slider
      });
    }

    var sliderArgs = {
      animation: 'slide',
      controlNav: false,
      animationLoop: false,
      slideshow: false,
      useCSS: false,
      prevText: '<i class=\"icn-slide-prev\"></i>',
      nextText: '<i class=\"icn-slide-next\"></i>',
      start: function(){
        if (callback) callback();
      },
      before: function(slider){
        dataLayer.push({'event': 'slideshow_interaction'});
      }
    };

    if(slideCount > 1) {
      sliderArgs.sync = $thumbs;

      if( $thumbs.find('.slides').children('li').length <= 7) {
        $thumbs.children('.flex-direction-nav').hide();
      }

      sliderArgs.start = function(slider) {
        onStart(slider);
      };
      sliderArgs.after = function(slider) {
        onAfter(slider);
      };
    }

    $slider.find('a.icon').not('.homepage-play').bind('click', function(event){
      event.preventDefault();
      onPlayClick($(this));
    })

    $slider.flexslider(sliderArgs);

  }

  var onStart = function(slider) {
    createMobileNav(slider);
  }

  var createMobileNav = function(slider) {
    var $slider = $(slider.context);
    var count = slider.count;
    var $mobile_nav = $('<ul class="mobile-nav">');

    var i = 0;
    while(i < count) {
      $nav_item = $('<li><a href="javascript:void(0);"></a></li>');
      if(i == 0) {
        $nav_item.find('a').addClass('flex-active');
      }
      $mobile_nav.append($nav_item);
      i++;
    }

    $slider.append($mobile_nav);

  }

  var onAfter = function(slider) {
    updateMobileNav(slider);
  }

  var updateMobileNav = function(slider) {
    var $slider = $(slider.context);
    var current_slide = slider.currentSlide;
    var $mobile_nav = $slider.find('.mobile-nav');
    $mobile_nav.find('a').removeClass('flex-active');
    $mobile_nav.find('a').eq(current_slide).addClass('flex-active');

  }

  var onPlayClick = function($that) {
    $this = $that;
    var video_id = $this.data('video-id');
    var width = 640;
    var height = 360;
    var interval = null;
    var iframe_src = 'https://www.youtube.com/embed/' + video_id + '?rel=0&wmode=transparent&autoplay=1&modestbranding=1';

    if (!!('ontouchstart' in window) || !!('onmsgesturechange' in window)) {
    }

    $.colorbox({
      iframe: true,
      fastIframe: false,
      innerWidth: width,
      innerHeight: height,
      href: iframe_src,
      onComplete: function(){
        $that = $(this);

        var ratio = (height / width);

        interval = setInterval(function(){

          var viewportWidth = $(window).width();
          var viewportHeight = $(window).height();

          if(viewportWidth * 0.8 < width) {
            var newWidth = Math.ceil(viewportWidth * 0.8);
            var newHeight = Math.ceil(newWidth * ratio);
          }
          else {
            var newWidth = width;
            var newHeight = height;
          }

          $that.colorbox.resize({innerWidth: newWidth, innerHeight: newHeight});
        }, 200);
      },
      onClosed: function(){
        clearInterval(interval);
      }
    });
  }

  init();
}
