document.addEventListener("DOMContentLoaded",function(){

let Naver = {
  init: function() {
    this.addEvents();
  },
  addEvents: function() {
  	const nav = document.querySelector('.site-header');
  	const navTop = nav.offsetTop;
    let navOpen = document.querySelector(".js-nav-open");
    let navClose = document.querySelector(".js-nav-close");
    let navGroup = document.querySelector(".js-nav-group-target");
    let logoWrap = document.querySelector(".js-newport-logo-wrapper");
    let navSearchOpen = document.querySelectorAll(".js-search-open");
    let navSearchClose = document.querySelector(".js-nav-search-close");
    let navSearch = document.querySelector(".js-nav-search");
    let subNavs = document.querySelectorAll(".js-sub-nav-toggle");
    let subSubNavs = document.querySelectorAll(".js-sub-sub-nav-toggle");

		function stickyNavigation() {
		  if (window.scrollY > navTop) {
		    nav.classList.add('--sticky');
		  } else {
		    nav.classList.remove('--sticky');
		  }
		}

		window.addEventListener('scroll', stickyNavigation);

    navOpen.addEventListener("click", (event) => {
      if (navGroup) {
        navGroup.classList.add("--active");
        logoWrap.classList.add("--active");
      }
    });
    navClose.addEventListener("click", (event) => {
      if (navGroup) {
        navGroup.classList.remove("--active");
        logoWrap.classList.remove("--active");
      }
    });
    if (subNavs) {
      for (let i = 0; i < subNavs.length; i++) {
        subNavs[i].addEventListener("click", (event) => {
          subNavs[i].parentElement.classList.toggle("--active");
          subNavs[i].parentElement.parentElement.classList.toggle("--active");
        });
      }
    }
    if (navSearch) {
      for (let i = 0; i < navSearchOpen.length; i++) {
        navSearchOpen[i].addEventListener("click", (event) => {
          navSearch.classList.add("--active");
        });
      }

      navSearchClose.addEventListener("click", (event) => {
        navSearch.classList.remove("--active");
      });
    }
    if (subSubNavs) {
      for (let i = 0; i < subSubNavs.length; i++) {
        subSubNavs[i].addEventListener("click", (event) => {
          subSubNavs[i].parentElement.classList.toggle("--active");
        });
      }
    }
  }
};

Naver.init();
});
